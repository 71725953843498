import React from 'react';
import LandingPage from './Components/LandingPage';
import About from './Components/AboutPage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {path:'/', element: <LandingPage />},
  {path: '/about', element: <About />}
])

function App() {
  return <RouterProvider router={router}/>
}

export default App;
