import Divider from '@mui/material/Divider';
import Image from 'react-bootstrap/Image';

const style = {
    "&::before": {
        borderColor: 'secondary.light'/*'linear-gradient(red, orange, yellow, green, blue)'*/
    },
    "&::after": {
        borderColor: 'secondary.light',
    },
    position: 'fixed',
    bottom: '1%',
    maxWidth: '95%',
    minWidth: '95%'
}
export default function NextDivider() {
    return(
            <Divider sx={style} variant="bottom">
                <a href="/about">
                    <Image src={"https://storage.googleapis.com/z_static/Scroll%20Down.png"} width={"50%"} height={"100%"}></Image>
                </a>
            </Divider>
    )
};



/*
<div class="container-fluid d-flex mx-auto"></div>


mb-3 mb-md-0 me-md-auto
*/