import * as React from 'react';
import Button from 'react-bootstrap/Button';
import "../Styles/sticky.css";

function StickyBar () {
    return (
        <>
        <style type="text/css">
        {`
        .btn-primary {
            font-family: ka1;
            background-color: rgba(0,0,0,0);
            margin-right: 5px;
            border: none;
        }`}
        </style>
        
        <div class="container-fluid d-none d-sm-block">
            <header id="headz" class="d-flex flex-wrap py-3 mb-4 border-5 border-bottom">
                <a href="/" class="navbar-brand d-flex mb-3 mb-md-0 me-md-auto">
                <img src="https://storage.googleapis.com/z_static/Dark%20Logo.png" width="100" height="70" alt="Logo" class="d-inline-block" />
                <span class="fs-4"></span>
                </a>
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a href="/about">
                    <Button variant="primary" alignItems="center">About</Button>
                    </a>
                </li>
                <li class="nav-item">
                    <Button variant="primary">Blog</Button>
                </li>
                <li class="nav-item">
                    <Button variant="primary">News</Button>
                </li>
                <li class="nav-item">
                    <Button variant="primary">Login</Button>
                </li>
            </ul>
            </header>
        </div>
        </>
    )
};

export default StickyBar;