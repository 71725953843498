import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
import logo from '../Media/Logo.png';
import Image from 'react-bootstrap/Image';

import { DropdownItem } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import "../Styles/drop.css";


/* Creates responsive component. Only renders for viewports less than the "sm" breakpoint.*/
const MobileWrapper = styled(Toolbar)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        display: 'flex',
        mr: 1
    },
    [theme.breakpoints.up("sm")]: {
        display: 'none'
    }
}));

/*const MobileMenu = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        size: "large",
        edge: "start",
        color: "inherit",
        ariaLabel: "menu",
        mr: 2
    },
    [theme.breakpoints.up("sm")]: {
        display: 'none'
    }
}));*/

/*const MobileLogo = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        size: "large",
        ariaLabel: "Icon",
        ariaHaspopup: true,
        ariaControls: "menu-appbar",
        color: "inherit",
        display: "flex",
        backgroundImage: `url(${logo})`
    },
    [theme.breakpoints.up("sm")]: {
        display: 'none'
    }
}));*/

/*const Bar = () => {
    return(
        <AppBar>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Image src={logo} />
                </Toolbar>
            </Container>
        </AppBar>
    )
}*/

/*export default function NextDivider() {
    return (
            <MobileWrapper>
                <MobileMenu>
                    <MenuIcon />
                    <Image className="ml-auto" src={logo} />
                </MobileMenu>
            </MobileWrapper>
            )
};*/

/*export default function MobileDrawerMenu() {
    return (
            <MobileWrapper>
                <nav class="navbar navbar-dark bg-none fixed-top">
                    <div class="container-fluid">
                        <Image src={logo} width={"20%"} height={"40%"}/>
                        
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"><span class="navbar-toggler-icon"></span></button>
                    
                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        
                        <div class="offcanvas-body">
                            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li>
              </li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
        </ul>

      </div>
    </div>
  </div>
</nav>
            </MobileWrapper>
            )
};*/

/*function MobileDrawerMenu() {
    return (
      <>
      <MobileWrapper>
        {[false].map((expand) => (
                    <Navbar key={expand} expand={expand} className="bg-none gap-3 px-3">
                            <Navbar.Brand >
                                <Image src={logo} width={"20%"} height={"40%"}/>
                                
                            
                    
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} placement="end" >
                                    
                                    <MenuIcon />
                            
                            
                            
                            <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">

                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="offCanvasTitle">
                                        <Image src={logo} width={"10%"} height={"20%"}/>
                                        {' '}Zidualz
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">

                                            <Nav.Link className="dropper">
                                                <DropdownItem className="dropper">About</DropdownItem>
                                            </Nav.Link>
                                                
                                            <Nav.Link>
                                                <DropdownItem className="dropper">Blog</DropdownItem>
                                            </Nav.Link>

                                    </Nav>
                                </Offcanvas.Body>

                            </Navbar.Offcanvas>
                            </Navbar.Toggle>
                            </Navbar.Brand>
                    </Navbar>
                ))}
                </MobileWrapper>
     </>
    );
}

export default MobileDrawerMenu;*/

function MobileDrawerMenu() {
    return (
      <>
      <MobileWrapper>
        {[false].map((expand) => (
                    <Navbar key={expand} expand={expand} className="bg-none gap-3 px-3">
                            <Navbar.Brand >
                                <Image src={logo} width={"20%"} height={"40%"}/>
                            </Navbar.Brand>
                    </Navbar>
                ))}
                </MobileWrapper>
     </>
    );
}

export default MobileDrawerMenu;