import Card from 'react-bootstrap/Card';
import Socials from './Socials';
import ZH from '../Media/HeadshotZH.png';
import JC from '../Media/HeadshotJC.png';
import '../Styles/bios.css';

function Item({ name }) {
  if (name == 'Joe Crimaldi') {
    return <Card.Img variant="top" src={JC} />
  }
  else if (name == 'Zachary Hanson')
  return <Card.Img variant="top" src={ZH} />
}

function ShortBio({ name }) {
  if (name == 'Joe Crimaldi') {
    return <Card.Text style={{color: 'white', fontFamily: 'editundo', fontSize: 'small'}}>
          Zidualz CFO, COO and Vice President.
    </Card.Text>
    }
  else if (name == 'Zachary Hanson') {
    return <Card.Text style={{color: 'white', fontFamily: 'editundo', fontSize: 'small'}}>
          Zidualz CEO, President.
    </Card.Text>
  }
  }
/* Write function similar to above such that the correct "bio" is rendered given the name property passed to the component. */

const Bios = ({name}) => {
  return (
    <Card style={{ width: '18rem'}} className="d-flex align-items-center justify-content-center cardStyle">
     { /*
         THE FOLLOWING STYLING APPROACH WORKS WHERE cardStyle IS DEFINED IN THE COMPONENT'S STYLE SHEET (bios.css) 
         IF ASSIGNED THE !important FLAG THEREBY OVERIDING ALL OVER STYLES.
        
         <Card style={{ width: '18rem''}} className="d-flex align-items-center justify-content-center cardStyle">
         
         THE FOLLOWING STYLING APPROACH ALSO WORKS WHERE backgroundColor is set inline via the style property using JSX. However, it does not seem
         to render it correctly upon pusing to server even though it is correct on the development server. Could be Firebase deployment issue or prior app version in Firebase?
         
         <Card style={{ width: '18rem', backgroundColor: 'black'}} className="d-flex align-items-center justify-content-center">
     */}
      <Item name={name}/>
      <Card.Body>
        <Card.Title style={{color: 'white', fontFamily: 'ka1', fontSize: 'medium'}}>{name}</Card.Title>
        <ShortBio name={name}/>
        <Socials />
      </Card.Body>
    </Card>
  );
}

export default Bios;